import Client from '@/services/Client'
const axios = new Client()
// src/util/notification.js

export const product = {
  state: {
    products: [],
    productReceivers: [],
    productItens: [],
    catalog:[]
  },
  getters: {
    getProducts: state => {
      return state.products
    },
    getProductItens: state => state.productItens,
    getProductReceivers: state => state.productReceivers,
    getCatalog: state => state.catalog
  },
  mutations: {
    setProducts: (state, value) => state.products = value,
    setProductReceivers: (state, value) => state.productReceivers = value,
    setCatalog:(state,value) => state.catalog = value,
  },
  actions: {
    listProducts({ commit }, url) {
      commit('setShowSkeletonTable', true)
      return new Promise((resolve, reject) => {
        let uri = url.url
        if(url){
          let providers = ''
          if(url.providers){
            providers = url.providers
            delete url.providers
          }
          let categories = ''
          if(url.categories){
            categories = url.categories
            delete url.categories
          }
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
          uri+=providers
          uri+=categories
        }
      
        axios.get(uri)
          .then(res => {
            commit('setProducts', res.data)
            commit('setShowSkeletonTable', false)
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    getProductsByOrder({ commit }, orderId) {
      
      return new Promise((resolve, reject) => {
        let uri = 'product/by-order/'+orderId
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    getProductMetrics({ commit }) {
      
      return new Promise((resolve, reject) => {
        let uri = 'product/metrics'
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    listProductMotiveTransaction({ commit }, url) {
      commit('setShowSkeletonTable', true)
      return new Promise((resolve, reject) => {
        let uri = 'product/movements/reason'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
      
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err)).finally(() => {
            commit('setShowSkeletonTable', false)
          })
      })
    },
    findProduct({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get('product/'+id)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    removeProduct({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete('product/'+id)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    createProduct({ commit }, data){
      return new Promise((resolve, reject) => {
        axios.post('product', data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    createProductImage({ commit }, data){
      return new Promise((resolve, reject) => {
        axios.post('product/image/'+data.product_id, data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    getGrade({ commit }, data){
      return new Promise((resolve, reject) => {
        axios.get('product/itens/get-grades', data)
          .then(res => {
            if(res.data) {
              resolve(res.data)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    removeProductImage({ commit }, data){
      return new Promise((resolve, reject) => {
        axios.delete('product/product-image/'+data.id)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    updateProduct({ commit }, data){
      return new Promise((resolve, reject) => {
        axios.put('product/'+data.product.id, data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    changeIsCatalog({ commit }, id){
      return new Promise((resolve, reject) => {
        axios.put('product/change-catalog/'+id)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    changeStatusMovement({ commit, dispatch }, data){
      return new Promise((resolve, reject) => {
        axios.put('product/movements/change/status/'+data.id, data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    createProductMovement({ commit, dispatch }, data){
      return new Promise((resolve, reject) => {
        axios.post('product/movements', data)
          .then(res => {
            if(res.data) {
              dispatch('listProducts', {url:'product'})
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    duplicationProduct({ commit, dispatch }, id){
      return new Promise((resolve, reject) => {
        axios.post('product/duplication/'+id)
          .then(res => {
            if(res.data) {
              dispatch('listProducts', {url:'product'})
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    createPurchase({ commit, dispatch }, data){
      return new Promise((resolve, reject) => {
        axios.post('product/movements/purchase', data)
          .then(res => {
            if(res.data) {
              dispatch('listProducts', {url:'product'})
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    updateProductMovement({ commit, dispatch }, data){
      return new Promise((resolve, reject) => {
        axios.put('product/movements/'+data.id, data)
          .then(res => {
            if(res.data) {
              dispatch('listProducts', {url:'product'})
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    indexProductMovementIndex({ commit, dispatch }, data){
      commit('setShowSkeletonTable', true)

      return new Promise((resolve, reject) => {

        let uri = 'product/movements/list'
        if(data){
          let query = new URLSearchParams(data).toString();
          uri += query ? '?'+query : '';
        }

        axios.get(uri, data)
          .then(res => {
            if(res.data) {
              resolve(res.data)
            } else reject(res)
          })
          .catch(err => reject(err)).finally(() =>  commit('setShowSkeletonTable', false)  )
      })
    },
    indexProductStock({ commit, dispatch }, data){
      commit('setShowSkeletonTable', true)

      return new Promise((resolve, reject) => {

        let uri = 'product/stock/index'
        if(data){
          let query = new URLSearchParams(data).toString();
          uri += query ? '?'+query : '';
        }

        axios.get(uri, data)
          .then(res => {
            if(res.data) {
              resolve(res.data)
            } else reject(res)
          })
          .catch(err => reject(err)).finally(() =>  commit('setShowSkeletonTable', false)  )
      })
    },

    showProductMovement({ commit, dispatch }, data){

      return new Promise((resolve, reject) => {
        let uri = 'product/movements/show/'+data.id
        axios.get(uri, data)
          .then(res => {
            if(res.data) {
              resolve(res.data)
            } else reject(res)
          })
          .catch(err => reject(err))
          .finally(() =>  {

          } )
      })
    },
    removeProductMovement({ commit, dispatch }, data){
      return new Promise((resolve, reject) => {
        axios.delete('product/movements/'+data.id, data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    fetchCatalogProduts({ commit, state },hash){
      return new Promise((resolve, reject) => {
        axios.get('products/catalog/'+hash+'?old=true')
          .then(res => {
            if(res.data) {
              commit('setCatalog',res.data);
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    }
  }
}
