import Client from '@/services/Client'
const axios = new Client()

export const order = {
  state: {
    orders: [],
    orderIds: [],
    loadTableOrder: true,
    noGetTotal: false,
    overFloawTotalOrder: true,
    orderProducts: [],
    orderId: '',
    orderSetting: {
      send_automatic: {},
      expirate_date: {},
    },
    orderTotal: {
      amount: 0,
      total_for_seller: 0,
      total_liquido: 0
    },
    orderFilter:{
      search: '',
      date_type: 'created_at',
      start_date: '',
      end_date: '',
    },
    statusOrder: {
      NEW_ORDER: 'Em aberto',
      COLLECTING: 'Coletando',
      COLLECTING: 'Coletando',
      PACKED: 'Embalado',
      SENT: 'Enviado',
      FINISHED: 'Finalizado',
      PENDENCY: 'Pendência',
      CANCELED: 'Cancelado',
    },
  },
  getters: {
    getOrders: state => state.orders,
    getOrdersTotal: state => state.orderTotal,
    getorderFilter: state => state.orderFilter,
    getStatusOrder: state => state.statusOrder,
    getLoadTableOrder: state => state.loadTableOrder,
    getOverFloawTotalOrder: state => state.overFloawTotalOrder,
    getNoGetTotal: state => state.noGetTotal,
    getOrderSetting: state => state.orderSetting,
    getOrderProducts: state => state.orderProducts,
    getOrderId: state => state.orderId,
    getOrderIds: state => state.orderIds,
  },
  mutations: {
    setOrders: (state, value) => state.orders = value,
    setOrdersTotal: (state, value) => state.orderTotal = value,
    setorderFilter: (state, value) => state.orderFilter = value,
    setLoadTableOrder: (state, value) => state.loadTableOrder = value,
    setNoGetTotal: (state, value) => state.noGetTotal = value,
    setOverFloawTotalOrder: (state, value) => state.overFloawTotalOrder = value,
    setOrderSetting: (state, value) => state.orderSetting = value,
    setOrderProducts: (state, value) => state.orderProducts = value,
    setOrderId: (state, value) => state.orderId = value,
    setOrderStatus(state, status) {
      state.orderStatus = status;
    },
    setOrderIds({ commit }, orderIds) {
      commit("SET_ORDER_IDS", orderIds);
    },
  },
  actions: {
    listOrders({ commit , dispatch, getters}, url) {
      dispatch('loadTableOrder', true)
      return new Promise((resolve, reject) => {
        commit('setShowSkeletonTable', true)
        let uri = 'order'
        if(url){
          uri = 'order'
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
      
        axios.get(uri)
          .then(res => {
            if(!getters.getNoGetTotal && !url.code){
              dispatch('getTotalToOrderHeader')
            }
            
            commit('setOrders', res)
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
            dispatch('loadTableOrder', false)
            commit('setShowSkeletonTable', false)
          })
      })
    },
    showOrder({ commit, getters }, data){
      console.log(data)
      let uri = 'order/show/'+data.id
      if(data){
        let query = new URLSearchParams(data).toString();
        uri += query ? '?'+query : '';
      }
    
      return new Promise((resolve, reject) => {
        axios.get(uri, data)
          .then(res => {
            if(res.data) {
              resolve(res.data)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    createSale({ commit, getters }, data){

      if(!getters.permission('order.create')){
        console.error('Sem permissão para acessar!');
        return false;
      }

      return new Promise((resolve, reject) => {
        axios.post('order', data)
          .then(res => {
            if(res.data) {

              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    updateDraft({ commit, getters }, data){

      if(!getters.permission('order.create')){
        console.error('Sem permissão para acessar!');
        return false;
      }

      return new Promise((resolve, reject) => {
        axios.post('order/update/draft', data)
          .then(res => {
            if(res.data) {

              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    deleteDraft({ commit, getters }, data){

      return new Promise((resolve, reject) => {
        axios.delete('order/budget/'+data.id, data)
          .then(res => {
            if(res.data) {

              resolve(res.data)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    orderSendMessage({ commit , dispatch}, data) {

      return new Promise((resolve, reject) => {
        axios.post('order/send-message/'+data.id, data)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    orderToSchare({ commit , dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.post('order/to-schare', data)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    orderToConfirm({ commit , dispatch}, id) {
      return new Promise((resolve, reject) => {
        axios.post('order/confirm/'+id)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },   
    getOrderPdf({ commit , dispatch}, id) {
      return new Promise((resolve, reject) => {
        axios.get('order/pdf/'+id)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    getOrderInsumoPdf({ commit , dispatch}, id) {
      return new Promise((resolve, reject) => {
        axios.get('order/pdf/insumos/'+id)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    updateStatusOrder({commit, dispatch}, data){
      return new Promise((resolve, reject) => {
        if(data.data){
          console.log('datadata', data)

          axios.post('order/change-many/status', {
            status_id: data.status_id,
            name: data.name,
            orders: data.data.map(item => {return item.id}), 
            move_task: data.move_task
          })
          .then(res => {
            commit('setNoGetTotal', true)
            dispatch('listOrders', {page: data.page, type: 'ORDER'})
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
            commit('setNoGetTotal', false)  
          })

        }else{
          axios.put('order/'+data.id, data)
          .then(res => {
            dispatch('listOrders', {page: data.page, type: 'ORDER'})
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
        }     
      })
    },
    getConfigOrder({commit}) {
      return new Promise((resolve, reject) => {
        const uri = 'order-settings'
        axios.get(uri)
          .then(res => {
            if(res.data) {
              let obj = {};
              res.data.forEach(item => {
                  obj[item.key] = item;
              });

              if (Object.keys(obj).length === 0) {
                obj = {send_automatic: {value: 0}, expirate_date: {value: 0}};
              }
              commit('setOrderSetting', obj)
              resolve(obj)
            } else reject(res)
          })
          .catch(err => reject(err));
      });
    },
    createUpdateOrderSettings({commit}, body) {
        return new Promise((resolve, reject) => {
          const uri = 'order-settings'
          axios.post(uri, body)
            .then(res => {
              if(res.data) {
                let obj = {};
                res.data.forEach(item => {
                  obj[item.key] = item;
                });
                commit('setOrderSetting', obj)
                resolve(res)
              } else reject(res)
            })
            .catch(err => reject(err));
        });
    },
    getTotalToOrderHeader({ commit , dispatch, getters}, url){
      return new Promise((resolve, reject) => {
        dispatch('overFloawTotalOrder', true)

        let uri = 'order/total-to-header'

        if(!url && getters.getorderFilter){
          url = getters.getorderFilter
        }

        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }

        axios.get(uri)
          .then(res => {
            if(res.data) {
              commit('setOrdersTotal', res.data)
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
            dispatch('overFloawTotalOrder', false)
          })
      })
    },
    updateOrderPartial({dispatch}, data) {
      return new Promise((resolve, reject) => {
        const uri = `order/update-partial/${data.id}`

        axios.post(uri, data.values)
          .then(res => {
            if(res.data) {
              if(!data.redirect){
                dispatch('listOrders', data.url);
              }
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      });
    },
    listProductsOfOrder({commit}, orderId) {
      return new Promise((resolve, reject) => {

        axios.get(`order/get-products/${orderId}`).then(res => {
          if (res.data) {
            commit('setOrderId', orderId);
            commit('setOrderProducts', res.data);
            resolve(res)
          } else reject(res);
        }).catch(err => reject(err));
      });
    },
    getDataInsumos({ commit }, orderId) {
      
      return new Promise((resolve, reject) => {
        let uri = 'order/data/insumos/'+orderId
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    addProductsOnOrder({dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.post(`order/add-products/${data.orderId}`, data.products).then(res => {
          if (res.data) {
            dispatch('listProductsOfOrder', data.orderId);
            resolve(res)
          } else reject(res);
        }).catch(err => reject(err));
      });
    },

    exportOrder({dispatch}, data) {
      return new Promise((resolve, reject) => {
        let uri= `order/export`
        if(data){
          let query = new URLSearchParams(data).toString();
          uri += query ? '?'+query : '';
        }

        axios.get(uri, data).then(res => {
          if (res.data) {
            resolve(res.data)
          } else reject(res);
        }).catch(err => reject(err));
      });
    },
    exportTransactionsOrder({dispatch}, orderId) {
      return new Promise((resolve, reject) => {
        let uri= `order/pdf/transactions/`+orderId
        axios.get(uri).then(res => {
          if (res) {
            resolve(res.data)
          } else reject(res);
        }).catch(err => reject(err));
      });
    },
    confirmTransactionsOrder({dispatch}, orderId) {
      return new Promise((resolve, reject) => {
        let uri= `order/confirm/payment/`+orderId
        axios.post(uri).then(res => {
          if (res) {
            resolve(res.data)
          } else reject(res);
        }).catch(err => reject(err));
      });
    },
    
    setFilterOrder({ commit }, data){
      commit('setorderFilter', data)
    },
    loadTableOrder({commit}, isLoad) {
      commit('setLoadTableOrder', isLoad)
    },
    overFloawTotalOrder({commit}, isLoad) {
      commit('setOverFloawTotalOrder', isLoad)
    },
    updateStatus({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        if (data.orders && Array.isArray(data.orders) && data.orders.length > 0) {
          axios
            .post("order/change-many/status", {
              status_id: data.status_id,
              name: data.name,
              orders: data.orders,
              move_task: data.move_task || false,
            })
            .then((res) => {
              if(!data.notList){
                commit("setNoGetTotal", true);
                dispatch("listOrders", { page: data.page || 1, type: "ORDER" });
              }
              
              resolve(res);
            })
            .catch((err) => {
              console.error("Erro ao atualizar status:", err);
              reject(err);
            })
            .finally(() => {
              commit("setNoGetTotal", false);
            });
        } else {
          reject(new Error("Nenhum pedido foi selecionado."));
        }
      });
    },
  }
}
